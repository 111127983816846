@keyframes moveInLeft {
  0% {
      opacity: 0; /* hide element */
      transform: translateX(-10rem); 
  }

  50% {
      opacity: 0;
      transform: translateX(1rem);
  }

  100% {
      opacity: 1;
      transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
      opacity: 0; /* hide element */
      transform: translateX(10rem); 
  }

  50% {
      opacity: 0;
      transform: translateX(-1rem);
  }

  100% {
      opacity: 1;
      transform: translate(0);
  }
}

@keyframes moveInTop {
  0% {
      opacity: 0; /* hide element */
      transform: translateY(-3rem); 
  }

  100% {
      opacity: 1;
      transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
      opacity: 0; /* hide element */
      transform: translateY(3rem); 
  }

  100% {
      opacity: 1;
      transform: translate(0);
  }
}

@keyframes easeIn {
  0% {
      opacity: 0; /* hide element */
  }
  
  80% {
    opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
