/*
Time for some gloss and other fancy stuff.
*/
@import '../../styles/variables';

#compass {
  width: 300px;
  height: 300px;
	border-radius: 50%;
	margin: 0 auto;
	position: relative;
	
  border: 10px solid #777;
  
  background-color: #e7a261;
  //#e7a261
	
	box-shadow:
		0 0 4px #000,
		0 0 1px 8px #ccc,
		5px 10px 15px #48436f;
	
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

#compass:before {
	content: '';
	
	border: 2px dashed #444;
	position: absolute;
	left: 10px; right: 10px;
	bottom: 10px; top: 10px;
	
	border-radius: 50%;
}


/* neat! lets position the directions now - N E S W */

#compass span {
	position: absolute;
  font-weight: bold;
}

/* North */
#compass span:nth-child(1) {
  left: calc(130px - 8px);
  top: 25px;
}
/* South */
#compass span:nth-child(3) {
  left: calc(130px - 6px);
  bottom: 25px;
}

/* East */
#compass span:nth-child(2) {
  right: 25px;
  top: calc(130px - 12px);
}
/* West */
#compass span:nth-child(4) {
  left: 25px;
  top: calc(130px - 12px);
}


/* Next up, is the pointer */

/* A bit of CSS3 animations on the pointer would be nice */
@keyframes rotate {
	from { transform: rotate(0deg); }
	to { transform: rotate(360deg); }
}

#pointer {
	width: 60px; height: 30px;
	
	/* Centering it */
	position: relative;
	left: 50%; top: 50%;
	margin-left: -30px; margin-top: -15px;
	
	/*background: black;*/
	animation: rotate 5s cubic-bezier(1,.2,.5,1) infinite alternate;
}

/* Now the trick i wanna use to create a diamond
shaped pointer is to skew the #pointer

But for some reason that did'nt work hence I did it
on #pointer:before */

#pointer:before {
	content: '';
	
	position: absolute;
	left: 0; right: 0;
	top: 0; bottom: 0;
	
	background: linear-gradient(
		-63deg,
		rgba(79,188,242,1) 1%,
		rgba(26,110,206,1) 50%,
		rgba(216,216,216,1) 51%,
		$color-primary 53%,
	);
	
	transform: skewX(60deg);
}

/* Looks good, lets make the pointer's joint now */

#pointer:after {
	content: '';
	
	width: 10px; height: 10px;
	background: #4781c0;
	border-radius: 50%;
	box-shadow:
		inset 0 0 4px 2px #4d4d4d,
		0 0 5px 1px white;
	
	/* centering it */
	position: absolute;
	left: 50%; top: 50%;
	margin-left: -5px; margin-top: -5px;
}

@media screen and (max-width: 767px) {
  #compass {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #compass {
    display: none;
  }
}

