@import './styles/variables';
@import './styles/base';
@import './styles/typography';
@import './styles/animations';

.App {
  text-align: center;
  color: $color-white;
  text-align: left;
  margin: 5rem 0;

  .content {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.animate {
  visibility: visible;
  animation-name: moveInBottom;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

@media screen and (max-width: 767px) {
  .App {
    margin: 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1659px) {
  .App {
    margin: 3rem;
  }
}
