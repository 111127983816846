body {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
}

.heading-primary {
  color: $color-white;
  line-height: 1;
  margin-left: 10rem;
  margin-top: 12rem;
  width: 100%;

  &--pre {
    display: block;
    font-size: 11rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    animation-name: moveInTop;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

  &--main {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    display: block;
    font-size: 18rem;
    margin-left: 7rem;
    margin: 4rem 0;
    max-width: 75%;
    background-color: $color-complimentary;
    padding: 5rem 5rem 10rem 5rem;
    box-shadow: 0 0.6rem 2rem darken($color: $color-container-background, $amount: 15%);
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-timing-function: ease-out;
  }

  &--sub {
    display: block;
    font-size: 2.4rem;
    margin-left: 8rem;
    line-height: 1.3;
    max-width: 40%;
    padding: 2.5rem;
    background-color: $color-container-background;
    margin-top: -8rem;
    box-shadow: 0 0.6rem 2rem darken($color: $color-container-background, $amount: 15%);
    animation-name: moveInRight;
    animation-duration: 2s;
    animation-timing-function: ease-out;
  }
}

@media screen and (max-width: 767px) {
  .heading-primary {
    width: 100%;
    margin-left: 0;

    &--pre {
      font-size: 4rem;
    }

    &--main {
      font-size: 5rem;
      max-width: 100%;
      padding: 4rem;
    }

    &--sub {
      max-width: 80%;
      font-size: 1.5rem;
      margin: 0 auto;
      margin-top: -5rem;
      padding: 1.5rem;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .heading-primary {
    width: 100%;
    margin-left: 0;

    &--pre {
      font-size: 6rem;
    }

    &--main {
      font-size: 10rem;
      max-width: 100%;
    }

    &--sub {
      max-width: 80%;
      font-size: 2.2rem;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1269px) {
  .heading-primary {
    width: 100%;
    margin-left: 0rem;
    margin-top: 22rem;

    &--pre {
      font-size: 7rem;
    }

    &--main {
      font-size: 12rem;
      max-width: 100%;
    }

    &--sub {
      max-width: 60%;
      font-size: 1.7rem;
      margin-left: 4rem;
    }
  }
}

@media screen and (min-width: 1269px) and (max-width: 1565px) {
  .heading-primary {
    width: 100%;

    &--pre {
      font-size: 7rem;
    }

    &--main {
      font-size: 12rem;
    }

    &--sub {
      font-size: 1.7rem;
      margin-left: 4rem;
    }
  }
}

@media screen and (min-width: 1566px) and (max-width: 1759px) {
  .heading-primary {
    width: 100%;

    &--pre {
      font-size: 8rem;
    }

    &--main {
      font-size: 13rem;
    }

    &--sub {
      font-size: 1.7rem;
      margin-left: 4rem;
    }
  }
}

@media screen and (min-width: 1760px) and (max-width: 1919px) {
  .heading-primary {
    width: 100%;

    &--pre {
      font-size: 8rem;
    }

    &--main {
      font-size: 15rem;
    }

    &--sub {
      font-size: 2rem;
      margin-left: 8rem;
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 2350px) {
  .heading-primary {
    width: 100%;

    &--pre {
      font-size: 10rem;
    }

    &--main {
      font-size: 17rem;
    }

    &--sub {
      font-size: 2rem;
      margin-left: 8rem;
    }
  }
}

.emphasis {
  color: $color-primary;
}


.section-heading {
  color: $color-white;
  display: grid;
  line-height: 1;
  margin-bottom: 15rem;

  &:first-of-type {
    margin-top: 0;
  }

  &--background {
    grid-area: 1 / 1 / 2 / 2;
    font-size: 9rem;
    color: $color-secondary;
    font-weight: 200;
    width: 50%;
  }

  &--foreground {
    grid-area: 2 / 1 / 2 / 2;
    color: $color-white;
    margin-left: 3rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;

    .title {
      font-size: 10rem;
      line-height: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .section-heading {
    line-height: 1;
    margin-bottom: 5rem;
  
    &--background {
      grid-area: 1 / 1 / 2 / 2;
      font-size: 6rem;
      color: $color-secondary;
      font-weight: 200;
      width: 50%;
    }
  
    &--foreground {
      grid-area: 2 / 1 / 2 / 2;
      color: $color-white;
      margin-left: 3rem;
      font-family: 'Montserrat', sans-serif;
      font-weight: 800;
  
      .title {
        font-size: 7rem;
        line-height: 0;
      }
    }
  }
}

a {
  text-decoration: none;
  font-size: 1.7rem;
  color: $color-white;
  display: inline-block;
  
  &:after {
    visibility: hidden;
    content: "";
    display: block;
    height: 0.2rem;
    top: 0.1rem;
    background-color: $color-primary;
    position: relative;
    transform: scaleX(0);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }

  &:hover:after {
    visibility: visible;
    transform: scaleX(1);
  }
}
