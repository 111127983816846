@import '../../styles/variables';

.icon {
  display: inline-block;

  &--container {
    text-decoration: none;
    display: inline-block;
    text-align: center;
    transition: all 0.1s linear 0.1s;

    &:hover {
      color: $color-primary;
      transform: translateY(-15%);
    }

    &__action {
      display: block;
      margin: auto;
    }

    &:after {
      display: none;

      &:hover {
        visibility: hidden;
      }
    }
  }
}